.origButton:hover {
  background-color: #00bbd1;
}
.origButton {
  background: #00bbd1;
  border: medium none;
  color: #fff;
  display: inline-block;
  float: right;
  font-size: 17px;
  line-height: 35px;
  padding: 5px 15px;
  text-align: center;
}
